<!-- eslint-disable max-len -->

<template>
  <div v-if="role.isV">
    <section class="hero pt-5 mt-5">
    </section>
    <div class="container-fluid mt-4 pt-2">
      <div class="row px-xl-3">
        <div class="col-sm-4 col-md-2 col-lg-2" style="background-color: #fff;">
          <div style="top: 120px;" class="sticky-top">
            <div class="sidebar-block">
              <div @click="showVendorSideNav" class="rounded-iwh block-header d-flex justify-content-between align-items-center" >
                <strong><a :href="`#${hash}`" class="accordion-link">Get Started</a></strong>
                <i class="fas text-right" :class="showVendorMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
              </div>
              <transition name="toggle">
                <div v-if="showVendorMenu" class="collapse show rounded-iwh" @click="scrollUp">
                  <nav class="nav nav-pills flex-column mb-0 pb-0">
                    <div v-for="(c,i) in comps" :key="i">
                      <!-- <a @click="comp=c.name" :href="`#${c.tab.toLowerCase()}`" class="nav-link" :class="comp==c.name? 'active':''"> {{c.tab}} <span v-if="c.new" class="badge badge-warning ml-1">New</span></a> -->
                      <router-link :to="{name: `vendor-${c.tab.toLowerCase()}`}" class="nav-link"> {{c.tab}} <span v-if="c.new" class="badge badge-warning ml-1">New</span></router-link>
                      <div v-if="i===1" class="dropdown-divider my-1"></div>
                    </div>
                  </nav>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="col tab-content">
          <!-- <keep-alive>
            <component v-bind:is="comp"/>
          </keep-alive> -->
          <router-view name="vendor" v-slot="{Component}">
            <transition name="fade">
              <component :is="Component"/>
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
// import EWallet from '../../components/Vendors/E-Wallet.vue';
// import Offerings from '../../components/Vendors/Offerings.vue';
// import PaymentsReceived from '../../components/Vendors/PaymentsReceived.vue';
// import UpcomingWeddings from '../../components/Vendors/UpcomingWeddings.vue';
// import VendorProfile from '../../components/Vendors/VendorProfile.vue';

export default {
  name: 'vendor-account',
  components: {
    // VendorProfile, UpcomingWeddings, EWallet, PaymentsReceived, Offerings,
  },
  data: () => ({
    comp: 'VendorProfile',
    hash: 'profile',
    comps: [
      { name: 'VendorProfile', tab: 'Profile', new: false },
      { name: 'EWallet', tab: 'Wallet', new: false },
      { name: 'Offerings', tab: 'Offerings', new: false },
      { name: 'Bookings', tab: 'Bookings', new: true },
      // { name: 'UpcomingWeddings', tab: 'Upcoming', new: true },
      { name: 'PaymentsReceived', tab: 'Transactions', new: false },
    ],
    showVendorMenu: true,
  }),
  created() {
    // this.$store.dispatch('setUserRole');
  },
  methods: {
    showVendorSideNav() {
      this.showVendorMenu = !this.showVendorMenu;
    },
    scrollUp() {
      // this.$store.dispatch('smoothScroll');
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.startMenu();
    },
    startMenu() {
      if (window.screen.width < 768) {
        setTimeout(() => {
          this.showVendorMenu = false;
        }, 100);
      }
      // this.showVendorMenu = false;
    },
  },
  computed: {
    ...mapState(['role']),
  },
};
</script>
<style scoped>
.router-link-active, .router-link-exact-active {
  background-color: #e9ecef;
}
.fade-enter-active, .fade-leave-active {
  transition: all 0s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
  /* transform: translateY(-3px); */
  opacity: 0;
}
.toggle-enter-active, .toggle-leave-active {
  transition: all 0s ease-in-out;
}
.toggle-enter-from, .toggle-leave-to {
  transform: translateY(-12px);
  opacity: 0;
}
</style>
